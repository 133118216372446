import { Component } from '@angular/core';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { FirebaseService } from './services/firebase/firebase.service';
import { TitleManagerService } from './shared/services/title-manager/title-manager.service';
import { NotificationService } from './shared/services/notification/notification.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { SettingsRepository } from './settings/state/settings.repository';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [IonApp, IonRouterOutlet],
})
export class AppComponent {
  constructor(
    private firebase: FirebaseService,
    private titleManager: TitleManagerService,
    private notifications: NotificationService,
  ) {
    this.firebase.init();
    this.titleManager.init();
    this.notifications.init();
  }
}
