import { Injectable } from '@angular/core';
import { TimerService } from 'src/app/timer/state/timer.service';
import { TimestampRepository } from 'src/app/timer/state/timestamps.repository';

@Injectable({
  providedIn: 'root',
})
export class TitleManagerService {
  constructor(
    private timerService: TimerService,
    private timestamps: TimestampRepository,
  ) {}

  init() {
    this.timerService.formattedTime$.subscribe((formattedTime) => {
      this.updateTimerTitle(formattedTime);
    });
  }

  updateTimerTitle(timer: string) {
    const mode = this.timestamps.getCurrentMode();
    if (mode === 'stopped') {
      document.title = 'Practical Focus';
      return;
    }
    const preTitle = mode === 'focus' ? 'Focus' : 'Rest';
    document.title = `${preTitle} | ${timer}`;
  }
}
